var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "documentos pt-8 p-4" },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c("h3", { staticClass: "vx-col w-full font-semibold mb-4" }, [
          _vm._v("\n      Documentos\n    "),
        ]),
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c(
              "vs-alert",
              {
                staticClass: "mb-5",
                attrs: { active: _vm.alertProposta, color: "warning" },
              },
              [
                _vm._v(
                  "\n        Adicione os documentos para realizar o cadastro de propostas.\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.listaDocumentos.length > 0
        ? _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                [
                  _c(
                    "vs-table",
                    {
                      staticClass: "vx-col w-full font-semibold",
                      attrs: { data: _vm.listaDocumentos },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ data }) {
                              return _vm._l(data, function (tr, indextr) {
                                return _c(
                                  "vs-tr",
                                  { key: indextr },
                                  [
                                    _c(
                                      "vs-td",
                                      {
                                        staticClass: "pl-6",
                                        attrs: { data: data[indextr].Status },
                                      },
                                      [
                                        _c(
                                          "vx-tooltip",
                                          {
                                            attrs: {
                                              text: data[indextr]
                                                .StatusDescription,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "material-icons",
                                                class: _vm.getTextColor(
                                                  data[indextr].Status
                                                ),
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm._f("statusDocumento")(
                                                        data[indextr].Status
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "vs-td",
                                      { attrs: { data: data[indextr].Name } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(data[indextr].Name) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        attrs: {
                                          data: data[indextr].Description,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(data[indextr].Description) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      [
                                        _c("vs-button", {
                                          attrs: {
                                            size: "large",
                                            type: "flat",
                                            icon: "description",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openModalListarDocumentos(
                                                data[indextr].Name,
                                                data[indextr].Id
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "vs-td",
                                      [
                                        _c("vs-button", {
                                          attrs: {
                                            disabled: data[indextr].Submitted,
                                            size: "large",
                                            type: "flat",
                                            icon: "cloud_upload",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalUploadOpen(
                                                data[indextr]
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              })
                            },
                          },
                        ],
                        null,
                        false,
                        3491256479
                      ),
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [
                            _vm._v("\n              Status\n            "),
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              "\n              Nome do documento\n            "
                            ),
                          ]),
                          _c("vs-th", [
                            _vm._v("\n              Descrição\n            "),
                          ]),
                          _c("vs-th", [
                            _vm._v("\n              Visualizar\n            "),
                          ]),
                          _c("vs-th", [
                            _vm._v("\n              Upload\n            "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm.possuiDocumentacaoPendente || _vm.possuiDocumentParaEnvio
        ? _c("div", { staticClass: "vx-row mt-5" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", disabled: !_vm.podeSubmeter },
                    on: {
                      click: function ($event) {
                        return _vm.submeterDocumentos(_vm.listaDocumentos)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold" }, [
                      _vm._v("Submeter para análise"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "vs-popup",
        {
          attrs: { title: "", active: _vm.modalListarDocumentos },
          on: {
            "update:active": function ($event) {
              _vm.modalListarDocumentos = $event
            },
          },
        },
        [
          _c("h3", { staticClass: "mb-10 ml-3" }, [
            _vm._v("Upload de Documento - " + _vm._s(_vm.uploadDocName)),
          ]),
          _vm.listaDocumentosDetalhes.length > 0
            ? _c(
                "vs-list",
                _vm._l(
                  _vm.listaDocumentosDetalhes,
                  function (documento, index) {
                    return _c(
                      "vs-list-item",
                      {
                        key: index,
                        attrs: { title: documento.OriginalFileName },
                      },
                      [
                        documento.Name
                          ? [
                              _c(
                                "a",
                                {
                                  directives: [
                                    { name: "storage", rawName: "v-storage" },
                                  ],
                                  staticClass:
                                    "vs-button vs-button-primary vs-button-flat includeIcon includeIconOnly large",
                                  attrs: {
                                    download: documento.OriginalFileName,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.baixarDocuemnto(
                                        documento.UniqueId
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "material-icons" },
                                    [
                                      _vm._v(
                                        "\n              cloud_download\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }
                ),
                1
              )
            : _c("p", { staticClass: "mb-4 text-center" }, [
                _vm._v("Carregando lista de documentos"),
              ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: "", active: _vm.modalUpload },
          on: {
            "update:active": function ($event) {
              _vm.modalUpload = $event
            },
          },
        },
        [
          _c("h3", { staticClass: "text-center" }, [
            _vm._v("Upload de Documento - " + _vm._s(_vm.uploadDocName)),
          ]),
          _c("div", { staticClass: "mb-5" }, [
            _c("div", { staticClass: "con-img-upload" }, [
              _vm.arquivo.base64
                ? _c("div", { staticClass: "img-upload" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-x-file",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.removeFile()
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons notranslate",
                            attrs: { translate: "no" },
                          },
                          [_vm._v("\n              clear\n            ")]
                        ),
                      ]
                    ),
                    _c(
                      "h4",
                      {
                        staticClass: "text-archive",
                        staticStyle: { width: "100%", "font-size": "12px" },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons notranslate",
                            attrs: { translate: "no" },
                          },
                          [_vm._v("\n              description\n            ")]
                        ),
                        _c("p", [
                          _c("b", [_vm._v(_vm._s(_vm.arquivo.Filename))]),
                        ]),
                      ]
                    ),
                  ])
                : _c("div", { staticClass: "con-input-upload" }, [
                    _c("input", {
                      attrs: { "data-v-480def7c": "", type: "file" },
                      on: {
                        change: function ($event) {
                          return _vm.getFiles($event)
                        },
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "text-input",
                        attrs: { "data-v-480def7c": "" },
                      },
                      [
                        _vm._v(
                          "\n            Clique aqui para selecionar o arquivo. "
                        ),
                      ]
                    ),
                    _c("span", {
                      staticClass: "input-progress",
                      staticStyle: { width: "0%" },
                      attrs: { "data-v-480def7c": "" },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "btn-upload-all vs-upload--button-upload",
                        attrs: {
                          "data-v-480def7c": "",
                          disabled: "disabled",
                          type: "button",
                          title: "Upload",
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons notranslate",
                            attrs: {
                              "data-v-480def7c": "",
                              translate: "translate",
                            },
                          },
                          [_vm._v("\n              cloud_upload\n            ")]
                        ),
                      ]
                    ),
                  ]),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full text-right" },
                [
                  _vm.arquivo.base64
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "ml-8",
                          attrs: { color: "success" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.enviarDocumento(_vm.idDocumento)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "font-semibold pr-2 pl-2" },
                            [
                              _vm._v(
                                "\n              Enviar documentos\n            "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "vs-button",
                        {
                          staticClass: "ml-8",
                          attrs: { disabled: "", color: "success" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.enviarDocumento(_vm.idDocumento)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "font-semibold pr-2 pl-2" },
                            [
                              _vm._v(
                                "\n              Enviar documentos\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }