<template>
  <div class="dados-bancarios pt-8  p-4">
    <div class="vx-row">
      <h3 class="vx-col w-full font-semibold mb-4">
        Dados Bancários
      </h3>
      <template
        v-if="
          editarBanco === true ||
            perfilHelpers.isInsurance()
        "
      >
        <div class="vx-col w-1/2">
          <p>Selecione o banco</p>
          <v-select
            label="Name"
            size="large"
            placeholder="Banco"
            class="w-full"
            :value="
              dadosBancarios.BankId && dadosBancarios.BankId > 0
                ? dadosBancarios.BankId
                : null
            "
            @input="setBanco"
            :reduce="bank => bank.Id"
            :options="bancos"
            :dir="'ltr'"
          />
        </div>
      </template>

      <template v-else>
        <div class="vx-col w-5/12">
          <vs-input
            label="Banco"
            size="large"
            :value="dadosBancarios.BankId | labelBanco(bancos)"
            disabled="true"
            class="w-full"
          />
        </div>
        <div class="vx-col w-1/12 mt-6 tex-rigth" v-if="podeEditar">
          <vx-tooltip text="Editar banco">
            <vs-button
              type="flat"
              color="primary"
              @click="editarBanco = true"
              size="large"
              icon="edit"
            ></vs-button>
          </vx-tooltip>
        </div>
      </template>

      <div class="vx-col w-1/2">
        <p>Tipo da conta</p>
        <v-select
          label="Name"
          size="large"
          placeholder="Selecione o tipo da conta"
          class="w-full"
          :value="
            dadosBancarios.BankAccountTypeId &&
            dadosBancarios.BankAccountTypeId > 0
              ? dadosBancarios.BankAccountTypeId
              : null
          "
          @input="setTipo"
          :options="accountTypeList"
          :reduce="accountType => accountType.Id"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
      </div>

      <div class="vx-col md:w-1/4 w-1/2 mt-5">
        <vs-input
          label="Agência"
          size="large"
          :disabled="!podeEditar"
          v-model="dadosBancarios.BankBranchNumber"
          v-mask="['###', '####', '#####', '######', '#######']"
          class="w-full"
        />
      </div>
      <div class="vx-col md:w-1/4 w-1/2 mt-5">
        <vs-input
          :disabled="!podeEditar"
          label="Digito"
          size="large"
          v-mask="['#', '##']"
          v-model="dadosBancarios.BankBranchDigit"
          class="w-full"
        />
      </div>

      <div class="vx-col md:w-1/4 w-1/2 mt-5">
        <vs-input
          :disabled="!podeEditar"
          label="Conta"
          size="large"
          v-model="dadosBancarios.BankAccountNumber"
          v-mask="[
            '###',
            '####',
            '#####',
            '######',
            '#######',
            '####################'
          ]"
          class="w-full"
        />
      </div>
      <div class="vx-col md:w-1/4 w-1/2 mt-5">
        <vs-input
          :disabled="!podeEditar"
          label="Digito"
          size="large"
          v-mask="['#']"
          v-model="dadosBancarios.BankAccountDigit"
          class="w-full"
        />
      </div>
    </div>

    <div class="vx-row mt-10">
      <h3 class="vx-col w-full font-semibold">
        Dados Reponsável Financeiro
      </h3>

      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-input
          :disabled="!podeEditar"
          label="Nome Completo"
          size="large"
          v-model="dadosBancarios.FinancialOfficer.Name"
          class="w-full"
        />
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5">
        <ValidationProvider name="cpf" rules="cpf" v-slot="{ errors }">
          <vs-input
            label="CPF"
            size="large"
            :disabled="!podeEditar"
            v-model="dadosBancarios.FinancialOfficer.CpfCnpj"
            v-mask="'###.###.###-##'"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5">
        <ValidationProvider name="email" rules="email" v-slot="{ errors }">
          <vs-input
            :disabled="!podeEditar"
            label="E-mail"
            type="email"
            size="large"
            class="w-full"
            v-model="dadosBancarios.FinancialOfficer.Email"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-input
          :disabled="!podeEditar"
          label="Telefone para contato"
          size="large"
          v-model="dadosBancarios.FinancialOfficer.PhoneNumber"
          v-mask="['(##) ####-####', '(##) #####-####']"
          class="w-full"
        />
      </div>
    </div>

    <div class="vx-col text-right w-full mt-5">
      <vs-button
        v-if="podeEditar"
        @click="salvarDadosFinanceiro()"
        color="primary"
      >
        Salvar dados Financeiros
      </vs-button>
    </div>
  </div>
</template>
<script>
import instance from "@/axios";
import { mask } from "vue-the-mask";
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "dados-bancarios",
  props: {
    cadastro: {
      type: Object,
      required: true
    }
  },
  directives: { mask },
  data() {
    return {
      bancos: [],
      editarBanco: false,
      accountTypeList: [],
      dadosBancarios: {
        BankId: null,
        BankBranchNumber: "",
        BankBranchDigit: "",
        BankAccountNumber: "",
        BankAccountDigit: "",
        BankAccountId: "",
        FinancialOfficer: {
          Name: "",
          Email: "",
          CpfCnpj: "",
          PhoneNumber: "",
          Id: 0
        }
      },

      perfilHelpers: PerfilHelpers
    };
  },
  computed: {
    podeEditar() {
      return this.perfilHelpers.checkPermission([
        this.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORES,
        this.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORA
      ]);
    }
  },
  watch: {
    cadastro(value) {
      if (!!Object.keys(value).length)
        this.dadosBancarios = this.$utils.deepClone(value);
    }
  },
  mounted() {
    this.getListaBancos();
    this.getListaTipoDeContas();
    if (!!Object.keys(this.cadastro).length)
      this.dadosBancarios = this.$utils.deepClone(this.cadastro);
  },
  methods: {
    setBanco(value) {
      this.dadosBancarios.BankId = value;
    },
    setTipo(value) {
      this.dadosBancarios.BankAccountTypeId = value;
    },
    getListaBancos() {
      if(!this.bancos || !this.bancos.length)
      return instance.get("/api/Core/GetBanks").then(response => {
        this.bancos = response.data.Response;
        return this.bancos;
      });
    },
    getListaTipoDeContas() {
      if(!this.accountTypeList || !this.accountTypeList.length)
      return instance.get("/api/Core/GetBankAccountTypes").then(response => {
        this.accountTypeList = response.data.Response;
        return this.accountTypeList;
      });
    },

    salvarDadosFinanceiro() {
      const dados = {
        BankId: this.dadosBancarios.BankId,
        BankBranchNumber: this.dadosBancarios.BankBranchNumber,
        BankBranchDigit: this.dadosBancarios.BankBranchDigit,
        BankAccountNumber: this.dadosBancarios.BankAccountNumber,
        BankAccountDigit: this.dadosBancarios.BankAccountDigit,
        BankAccountId:
          this.dadosBancarios.PersonBankAccountId != undefined
            ? this.dadosBancarios.PersonBankAccountId
            : this.dadosBancarios.BankAccountId,
        FinancialOfficer: this.dadosBancarios.FinancialOfficer,
        BankAccountTypeId: this.dadosBancarios.BankAccountTypeId,
        Id: this.cadastro.Id || this.cadastro.BrokerId
      };

      this.$onpoint.loadingModal("Processando sua solicitação", () => {
        return instance({
          method: "post",
          url: "/api/Broker/SaveBrokerFinancialData",
          data: dados
        })
          .then(() => {
            this.$onpoint.successModal("Dados atualizados com sucesso");
            this.$emit("updateFinancialData", this.dadosBancarios);
          })
          .catch(errors => {
            this.$onpoint.errorModal(errors.response.data.Errors);
          });
      });
    }
  }
};
</script>
<style lang="scss">
.disabled {
  background-color: rgba(239, 239, 239, 0.3);
}

h3 {
  color: rgba(var(--vs-primary), 1);
}
</style>
