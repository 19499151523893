<template>
  <div class="dados-bancarios pt-8 p-4">
    <div class="vx-row">
      <h3 class="vx-col w-full font-semibold">
        Sócios/ Administradores PEP
      </h3>
    </div>

    <div class="vx-row mt-5" v-for="(socio, index) in socios" :key="index">
      <template v-if="socio">
        <div class="vx-col w-1/2">
          <vs-input
            label="Nome"
            disabled="true"
            :value="socio.Name"
            size="large"
            class="w-full"
          />
        </div>
        <div class="vx-col w-1/2">
          <p>CPF</p>
          <the-mask
            class="w-full input-the-mask"
            :mask="['###.###.###-##']"
            disabled="true"
            :masked="true"
            :value="socio.CpfCnpj"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mask, TheMask } from "vue-the-mask";

export default {
  name: "dados-pep",
  components: { TheMask },
  props: {
    QSAs: {
      type: Array,
      required: true
    },
    cadastro: {
      type: Object,
      required: true
    }
  },
  directives: { mask },
  data() {
    return {
      socios: []
    };
  },
  mounted() {
    this.socios = this.$utils.deepClone(this.QSAs);
  }
};
</script>
